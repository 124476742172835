<template>
  <b-form-group
    v-if="!question.locked"
    :label="question.title"
  >
    <validation-provider
      #default="{ errors }"
      :name="question.title"
      rules="required"
    >
      <b-form-radio
        v-for="option in question.options"
        :key="option.id"
        v-model="question.selected"
        :name="'option_' + question.id + option.id"
        :value="option.id"
        :disabled="question.locked"
        @change="selectItem(question.id,option.id)"
      >
        {{ option.title }}
      </b-form-radio>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormRadio } from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Question',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormRadio,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    maResult() {
      return this.$store.getters['marketAnalysis/getMaResult']
    },
  },
  created() {
    localize(this.$i18n.locale)
  },
  methods: {
    selectItem(questionsId, optionId) {
      const result = this.maResult
      const item = result.findIndex(data => data.id_ma_questions === questionsId)
      this.maResult[item].id_ma_option_group_items = optionId
    },
  },
}
</script>

<style scoped>

</style>
