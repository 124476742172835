<template>
  <div>
    <startup-card />
    <result-card
      v-if="marketAnalysisSaveStatus.status !== null"
      :result="marketAnalysisSaveStatus"
    />
    <validation-observer
      v-else
      ref="simpleRules"
    >
      <template v-if="marketAnalysis.length">
        <div
          v-for="(item,index) in marketAnalysis"
          :key="index"
        >
          <b-card :title="item.title">
            <b-row
              v-for="question in item.questions"
              :key="question.id"
            >
              <b-col cols="12">
                <question :question="question" />
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div>
          <b-form-checkbox
            v-model="draftStatus"
            class="mb-1"
            switch
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-form-checkbox>
          <draft-save-alert v-if="draftStatus" />
        </div>
        <template v-if="draftStatus">
          <b-button
            variant="warning"
            @click="saveDraft"
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="primary"
            @click="saveData"
          >
            {{ $t('Gönder') }}
          </b-button>
        </template>
      </template>
    </validation-observer>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import StartupCard from '@/views/App/Interests/Requests/components/StartupCard.vue'
import ResultCard from '@/views/App/NewStartups/ResultCard.vue'
import Question from '@/views/App/NewStartups/MarketAnalysis/Question.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import DraftSaveAlert from '@/views/App/NewStartups/components/DraftSaveAlert.vue'

export default {
  name: 'MarketAnalysis',
  components: {
    DraftSaveAlert,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    StartupCard,
    ResultCard,
    Question,
  },
  data() {
    return {
      required,
      draftStatus: false,
    }
  },
  computed: {
    marketAnalysis() {
      return this.$store.getters['marketAnalysis/getMarketAnalysis']
    },
    maResult() {
      return this.$store.getters['marketAnalysis/getMaResult']
    },
    marketAnalysisSaveStatus() {
      return this.$store.getters['marketAnalysis/getMarketAnalysisSaveStatus']
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getStartup()
    this.getMarketAnalysis()
  },
  methods: {
    getStartup() {
      this.$store.dispatch('memberStartups/memberStartupView', this.$route.params.id)
    },
    getMarketAnalysis() {
      this.$store.dispatch('marketAnalysis/marketAnalysis', {
        id_startups: this.$route.params.id,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('marketAnalysis/saveData', this.maResult)
        }
      })
    },
    saveDraft() {
      this.$store.dispatch('marketAnalysis/saveDraft', this.maResult)
    },
  },
}
</script>
<style scoped></style>
