<template>
  <b-card class="bg-primary text-light text-center">
    <div>
      <feather-icon
        :icon="result.status? 'CheckIcon' : 'XIcon'"
        size="48"
      />
    </div>
    <div class="font-medium-4 font-weight-bold mb-2">
      {{ result.message }}
    </div>
    <!--    <div>-->
    <!--      <b-button-->
    <!--        variant="light"-->
    <!--      >-->
    <!--        {{ $t('Raporu Görüntüle') }}-->
    <!--      </b-button>-->
    <!--    </div>-->
  </b-card>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  name: 'ResultCard',
  components: {
    BCard,
    BButton,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
}
</script>
